import React, { useState, useEffect } from "react";
import {useLocation, Link } from "react-router-dom";

import "./CityPage2.css";

import * as PinoUtil from "../Common/PinoUtil";



function CityPage2(props) {    

    const location = useLocation();
    const [map_url, set_map_url] = useState('');

    useEffect(()=> {
        console.log("execute once");
        LoadCityData();
    }, []);



    function LoadCityData() {
        var cur_city_id = location.pathname.split('/')[1];

        console.log(cur_city_id);

        set_map_url(PinoUtil.TMI_CITY_LIST.get(cur_city_id).city_map);

    }



    
    return (
        <div id='city_page_div'>


            <div id='city_page_top_div'>
                ⬇️⬇️ 장소 리스트를 보려면    <img src='res/list_icon2.png' id='top_div_list_icon' /> 를 누르세요.
            </div>


            <iframe src={map_url} className="iframe_map"></iframe>

            <div id='city_page_bottom_div'>
                🙊 본 여행정보는 AI 반, 사람 반으로 만들어졌으며, 틀린 정보가 있을 수 있습니다. 잘못된 정보는 아래 메일주소로 제보해주세요. 🙏
            </div>

        </div>
    );
}





export default CityPage2; 