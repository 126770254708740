

//export const GOOGLE_API_KEY = 'AIzaSyAtpGDKBEoXaNjRJ_sTeykecnmxFhkadk4';// AIzaSyCLx7OFHvpi8sbjBEeXqaRhETPqrKuKil8

/*
export const PINO_COUNTRY_LIST = new Map([
    ["mo", { country_name:"마카오", cities:['Macau']} ],
    ["hk", { country_name:"홍콩", cities:['HongKong']} ]
]);
*/
// ---------------------------------------------------------------------------------------------------------------------------------

//export const TMI_REGION_CATEGORIES = ['일본','동아시아'];
export const TMI_REGION_CATEGORIES = ['일본','동남아','동아시아','미주','유럽','인도양','대양주','중동'];


export const TMI_CITY_LIST = new Map([
    ["NewYorkCity", { city_name:"뉴욕", city_map:"https://www.google.com/maps/d/embed?mid=11FHLXcQEvnArawZQGCerspqzBdqjtR4&ehbc=2E312F&noprof=1", cc:"US", flight_hour: 14, best_month:[4,5,6,9,10], region:"미주", is_live:false }],
    ["LosAngeles", { city_name:"로스앤젤리스", city_map:"https://www.google.com/maps/d/embed?mid=1kV1NRf2lMxtEFHgzQvjO--FnTzHC67k&ehbc=2E312F&noprof=1", cc:"US", flight_hour: 12, best_month:[3,4,5,10,11], region:"미주", is_live:false }],
    ["SanFrancisco", { city_name:"샌프란시스코", city_map:"https://www.google.com/maps/d/embed?mid=1WZe1LEkWEq53eQs2mz3nBC4s-Fb6SIE&ehbc=2E312F&noprof=1", cc:"US", flight_hour: 11, best_month:[4,5,6,9,10], region:"미주", is_live:false }],
    ["Hawaii", { city_name:"하와이", city_map:"https://www.google.com/maps/d/embed?mid=1laX-TnE2jny3ZotokzWMYyQ4KZfqd1M&ehbc=2E312F&noprof=1", cc:"US", flight_hour: 9, best_month:[4,5,6,9,10,11], region:"미주", is_live:false }],
    ["Bali", { city_name:"발리", city_map:"https://www.google.com/maps/d/embed?mid=1D4a9NJGFT9Z2Pp58R95ZVBCoCDiW768&ehbc=2E312F&noprof=1", cc:"IN", flight_hour: 7, best_month:[5,6,7,8,9], region:"동남아", is_live:false }],
    ["Phuket", { city_name:"푸켓", city_map:"https://www.google.com/maps/d/embed?mid=1S6n05y8_A-EpPNjPw23ec7NnFW0SggE&ehbc=2E312F&noprof=1", cc:"TH", flight_hour: 6, best_month:[12,1,2,3,4], region:"동남아", is_live:false }],
    ["Milan", { city_name:"밀라노", city_map:"https://www.google.com/maps/d/embed?mid=1biqi8y7q5TOiWfAO9_aCJHBtBtxdDFE&ehbc=2E312F&noprof=1", cc:"IT", flight_hour: 15, best_month:[4,5,6,9,10], region:"유럽", is_live:false }],
    ["London", { city_name:"런던", city_map:"https://www.google.com/maps/d/embed?mid=1hw3QYw0FttcZ57TRmjLrFsYyxE-50Ag&ehbc=2E312F&noprof=1", cc:"GB", flight_hour: 15, best_month:[5,6,7,9], region:"유럽", is_live:false }],
    ["Paris", { city_name:"파리", city_map:"https://www.google.com/maps/d/embed?mid=1r6C5kaTDlZCaNAxdPRu0N9BYaCfuR2w&ehbc=2E312F&noprof=1", cc:"FR", flight_hour: 14, best_month:[5,6,9,10], region:"유럽", is_live:false }],
    ["Rome", { city_name:"로마", city_map:"https://www.google.com/maps/d/embed?mid=1fChFPpZvSK8vqFVPbhjMBq9ByRlJbLw&ehbc=2E312F&noprof=1", cc:"IT", flight_hour: 13, best_month:[4,5,10], region:"유럽", is_live:false }],
    ["Maldives", { city_name:"몰디브", city_map:"https://www.google.com/maps/d/embed?mid=1S_8dKzbi_-BJ0YOtPbnQvUCkEeDlHI0&ehbc=2E312F&noprof=1", cc:"MV", flight_hour: 13, best_month:[1,2,3,11,12], region:"인도양", is_live:false }],
    ["Prague", { city_name:"프라하", city_map:"https://www.google.com/maps/d/embed?mid=1fW6cFMmAwlSbqlRDbViGrf5Kt8-BTFs&ehbc=2E312F&noprof=1", cc:"CZ", flight_hour: 17, best_month:[5,6,9], region:"유럽", is_live:false }],
    ["Tokyo", { city_name:"도쿄", city_map:"https://www.google.com/maps/d/embed?mid=1vR718RSvSMub-vPU0cFpz3u9zaZl7Gs&ehbc=2E312F&noprof=1", cc:"JP", flight_hour: 2, best_month:[3,4,5,10,11], region:"일본", is_live:false }],
    ["Okinawa", { city_name:"오키나와", city_map:"https://www.google.com/maps/d/embed?mid=1ZRLprZkK6MTPSp_blp2Po2CAojxzSgE&ehbc=2E312F&noprof=1", cc:"JP", flight_hour: 2, best_month:[4,5,10,11], region:"일본", is_live:false }],
    ["Osaka", { city_name:"오사카", city_map:"https://www.google.com/maps/d/embed?mid=1nu1YvXi71BH-btEow8YjEcpHjTsh94M&ehbc=2E312F&noprof=1", cc:"JP", flight_hour: 2, best_month:[4,5,10,11], region:"일본", is_live:false }],
    ["Sydney", { city_name:"시드니", city_map:"https://www.google.com/maps/d/embed?mid=1Jk9nctPUyhkF05Ix_2mMOLSn2G8ZXdI&ehbc=2E312F&noprof=1", cc:"AU", flight_hour: 10, best_month:[3,4,10,11], region:"대양주", is_live:false }],
    ["DaNang", { city_name:"다낭", city_map:"https://www.google.com/maps/d/embed?mid=170Cd6GMqtYYHp3l7OseN8TbpZ3Maijs&ehbc=2E312F&noprof=1", cc:"VN", flight_hour: 5, best_month:[2,3,4,11,12], region:"동남아", is_live:false }],
    ["Dubai", { city_name:"두바이", city_map:"https://www.google.com/maps/d/embed?mid=1DWaMklRNVtpXSn8EHyDRtJCjJZzpMV4&ehbc=2E312F&noprof=1", cc:"AE", flight_hour: 10, best_month:[1,2,3,11,12], region:"중동", is_live:false }],
    ["Cebu", { city_name:"세부", city_map:"https://www.google.com/maps/d/embed?mid=1upAgVmvxmAbsZTWFbVTJdhu8NmPdeNw&ehbc=2E312F&noprof=1", cc:"PH", flight_hour: 4, best_month:[1,2,3,12], region:"동남아", is_live:false }],
    ["Boracay", { city_name:"보라카이", city_map:"https://www.google.com/maps/d/embed?mid=1n9yEPZXBfVGDzXabU1caBXZtQHDKZVQ&ehbc=2E312F&noprof=1", cc:"PH", flight_hour: 5, best_month:[1,2,3,12], region:"동남아", is_live:false }],
    ["Fukuoka", { city_name:"후쿠오카", city_map:"https://www.google.com/maps/d/embed?mid=1GFLo0Fyf20ojoTvza0uR7T7Ocj-U9t4&ehbc=2E312F&noprof=1", cc:"JP", flight_hour: 1, best_month:[4,5,10,11], region:"일본", is_live:false }],
    ["NhaTrang", { city_name:"나트랑", city_map:"https://www.google.com/maps/d/embed?mid=14czxiv2n2L6f1c4AnMyZPXXyKud5FjE&ehbc=2E312F&noprof=1", cc:"VN", flight_hour: 5, best_month:[2,3,4,11,12], region:"동남아", is_live:false }],
    ["Bangkok", { city_name:"방콕", city_map:"https://www.google.com/maps/d/embed?mid=1fKy1ZHK2ipA4o4PVcZ92PKpwzHwkoPI&ehbc=2E312F&noprof=1", cc:"TH", flight_hour: 6, best_month:[11,12,1,2], region:"동남아", is_live:false }],
    ["Singapore", { city_name:"싱가포르", city_map:"https://www.google.com/maps/d/embed?mid=1mLXGZ-CXFAHIQXUfoMW7ccl4nwDJNjw&ehbc=2E312F&noprof=1", cc:"SG", flight_hour: 7, best_month:[2,3,7,8], region:"동남아", is_live:false }],
    ["LasVegas", { city_name:"라스베가스", city_map:"https://www.google.com/maps/d/embed?mid=1jYld2o_eR_R-I-UjpEdR42zcxM3-cRc&ehbc=2E312F&noprof=1", cc:"US", flight_hour: 14, best_month:[3,4,5,10], region:"미주", is_live:false }],
    ["HongKong", { city_name:"홍콩", city_map:"https://www.google.com/maps/d/embed?mid=1xlwHozQqGRf6358pl66q2ukLaNgTQiQ&ehbc=2E312F&noprof=1", cc:"HK", flight_hour: 4, best_month:[3,4,10,11], region:"동아시아", is_live:false }],
    ["Macau", { city_name:"마카오", city_map:"https://www.google.com/maps/d/embed?mid=12eefVMssXrmw0ujP7YnqeLpbjT0PTbk&ehbc=2E312F&noprof=1", cc:"MK", flight_hour: 4, best_month:[3,4,10,11], region:"동아시아", is_live:false }],
    ["Madrid", { city_name:"마드리드", city_map:"https://www.google.com/maps/d/embed?mid=1UcACKl7sDZ1ptVDIrjJPcz_YOq_WyRY&ehbc=2E312F&noprof=1", cc:"ES", flight_hour: 15, best_month:[4,5,9,10], region:"유럽", is_live:false }],
    ["Guam", { city_name:"괌", city_map:"https://www.google.com/maps/d/embed?mid=1sg0MawDA1eqWAmBq2WFGGzrWYtbGTGQ&ehbc=2E312F&noprof=1", cc:"MP", flight_hour: 4, best_month:[2,3,4,12], region:"대양주", is_live:false }],
    ["Barcelona", { city_name:"바르셀로나", city_map:"https://www.google.com/maps/d/embed?mid=16kHN2nX90UpXGZACN6070Qjf9CElBUQ&ehbc=2E312F&noprof=1", cc:"ES", flight_hour: 14, best_month:[5,6,9,10], region:"유럽", is_live:false }],
    ["HoChiMinh", { city_name:"호치민", city_map:"https://www.google.com/maps/d/embed?mid=1ot_Ztd5W0nOvtfgCnjlUm-iCiSjVidY&ehbc=2E312F&noprof=1", cc:"VN", flight_hour: 5, best_month:[1,2,3,11,12], region:"동남아", is_live:false }],
    ["Taipei", { city_name:"타이베이", city_map:"https://www.google.com/maps/d/embed?mid=1HTt8PR-c1ik9f9IkbZb_0xu-W4-zW14&ehbc=2E312F&noprof=1", cc:"TW", flight_hour: 3, best_month:[3,4,11], region:"동아시아", is_live:false }],
    ["Sapporo", { city_name:"삿포로", city_map:"https://www.google.com/maps/d/embed?mid=1hJxFIXSXx5PAHZSYho9O70cx56T0QUg&ehbc=2E312F&noprof=1", cc:"JP", flight_hour: 3, best_month:[6,7,8,9], region:"일본", is_live:false }],
    ["KoSamui", { city_name:"코사무이", city_map:"https://www.google.com/maps/d/embed?mid=10WhfO4RiuoRIHobhlUhYst4NWbU9ems&ehbc=2E312F&noprof=1", cc:"PH", flight_hour: 12, best_month:[1,2,3,12], region:"동남아", is_live:false }],
    ["Cancun", { city_name:"칸쿤", city_map:"https://www.google.com/maps/d/embed?mid=1AQCOaGTxAGEfvj6JDxE1MUHBd_0b-wM&ehbc=2E312F&noprof=1", cc:"MX", flight_hour: 19, best_month:[2,3,4,11], region:"미주", is_live:false }],
    ["Saipan", { city_name:"사이판", city_map:"https://www.google.com/maps/d/embed?mid=19qlTpBlP-OqUtqeu3Ywr_vgH9S0uPd0&ehbc=2E312F&noprof=1", cc:"MP", flight_hour: 5, best_month:[2,3,4,12], region:"대양주", is_live:false }],
    ["Manila", { city_name:"마닐라", city_map:"https://www.google.com/maps/d/embed?mid=1036hf9lrBBz5uXCbCxNMD_hmsa226ho&ehbc=2E312F&noprof=1", cc:"PH", flight_hour: 4, best_month:[1,2,12], region:"동남아", is_live:false }],
    ["Venice", { city_name:"베니스", city_map:"https://www.google.com/maps/d/embed?mid=12kAakTHYZ5f16BMP3_cAL7KTJToWcXs&ehbc=2E312F&noprof=1", cc:"IT", flight_hour: 15, best_month:[4,5,9,10], region:"유럽", is_live:false }],
    ["Vienna", { city_name:"비엔나", city_map:"https://www.google.com/maps/d/embed?mid=1EQLyxMdHCIdvDRhLgknavjSVCF12l7g&ehbc=2E312F&noprof=1", cc:"AT", flight_hour: 15, best_month:[5,6,9,10], region:"유럽", is_live:false } ]
]);

//export const TMI_CITY_LIST_STR = 
//"137|마카오|Macau|MO|동북아|22.178043|113.556248|https://live.staticflickr.com/5455/8863435693_1895dde067_q.jpg|-480|4|true|zh,pt|MOP||80,83,85,87,86,85,85,84,84,83,81,79|0.4,1.1,1.2,2.8,7.5,6,6.2,7.4,12.6,9.1,2.3,0.4|11.8,12.1,12.4,12.8,13.1,13.3,13.2,12.9,12.6,12.2,11.9,11.7|true|2024-08-14 16:00:47.115|2024-08-14 16:00:47.115|마카오|1|MFM|Macau|https://www.google.com/maps/d/embed?mid=12eefVMssXrmw0ujP7YnqeLpbjT0PTbk&ehbc=2E312F&noprof=1";



//export const TMI_CITY_LIST_STR = 
//`Tokyo	도쿄	JP	일본	https://www.google.com/maps/d/embed?mid=1vR718RSvSMub-vPU0cFpz3u9zaZl7Gs&ehbc=2E312F&noprof=1	2	3,4,5,10,11
//Macau	마카오	MK	동아시아	https://www.google.com/maps/d/embed?mid=12eefVMssXrmw0ujP7YnqeLpbjT0PTbk&ehbc=2E312F&noprof=1	4	3,4,10,11`;



export function TMI_GetParsedCityListData() {    
    var city_list = [];
    /*
    const rows = TMI_CITY_LIST_STR.split("\n");
    for(let i = 0; i < rows.length; i++){
        //console.log(rows[i]);
        let values = rows[i].split("	");

        var c_json = {};
        c_json.name = values[1];
        c_json.cid = values[0].replace(/ /g, '');
        c_json.cc = values[2];
        c_json.region = values[3];

        city_list.push(c_json);      
    }

    */

    for (const [c_id, c_data] of TMI_CITY_LIST) {
        var c_json = {};
        c_json.name = c_data.city_name;
        c_json.cid = c_id;
        c_json.cc = c_data.cc;
        c_json.region = c_data.region;

        city_list.push(c_json);      
    }

    console.log(city_list)

    return city_list;
}

export function TMI_GetCityDisplayName(city_id) {
    return TMI_CITY_LIST.get(city_id).city_name;

    /*
    const rows = TMI_CITY_LIST_STR.split("\n");
    for(let i = 0; i < rows.length; i++){
        let values = rows[i].split("	");
        if(values[0].replace(/ /g, '') == city_id) {
            return values[1];
        }
    }
    */
    return "Error";
}


export function TMI_ConvertHotelPriceText(price) {
    
    return "1박 평균 가격: "+(price*1350).toLocaleString() + "원";
}

export function TMI_ConvertYouTubeURL(input) {
    var video_id = input;
    video_id = video_id.replace('https://www.youtube.com/watch?v=', '');

    video_id = video_id.replace('https://www.youtube.com/shorts/', '');

    var output = "https://www.youtube.com/embed/"+video_id;

    output = output.replace(/&t=(\d+)s/g, '?start=$1');     //  '&t=139s' -> '?start=139' 
    console.log("TMI_ConvertYouTubeURL :  "+input+"---"+output)
    return output;
}


export function TMI_ParseCityData(raw_data) {
    //console.log(raw_data);

    var ret_data = {};
    ret_data.map_url = '';
    ret_data.spots = [];
    
    var place_index=1;
    var food_index=1;
    var hotel_index=1;
    var etc_index=1;
    
    const rows = raw_data.split("\n");
    for(let i = 0; i < rows.length; i++){
        //console.log(rows[i]);

        let values = rows[i].split("|");

        var c_json = {};
        c_json.type = values[0];
        if(c_json.type == 'CITY') {
            ret_data.map_url = values[1];
        }
        else {
            if(c_json.type == 'PLACE') {
                //id, name_kor, img, subtype, website, description, price, price_link, opening_hour, youtube, google_map_link 
                c_json.name = values[2];
                c_json.img = values[3];
                c_json.img2 = '';
                c_json.img3 = '';
                c_json.subtype = values[4];
                c_json.website = values[5];
                c_json.description = values[6];
                c_json.price = values[7];
                c_json.price_link = values[8];
                c_json.opening_hour = values[9];
                c_json.youtube = values[10];
                c_json.googlemap = values[11];
                c_json.price_link_name = values[12];
                c_json.popularity = values[13];
                c_json.index = place_index; 
                ret_data.spots.push(c_json);  
                place_index++;
            }
            else if(c_json.type == 'FOOD') {
                //id, name_kor, img, img2, img3 subtype, website, description, menu, opening_hour, youtube, google_map_link, popularity, name_local 
                c_json.name = values[2];
                c_json.img = values[3];
                c_json.img2 = values[4];
                c_json.img3 = values[5];
                c_json.subtype = values[6];
                c_json.website = values[7];
                c_json.description = values[8];
                c_json.menu = values[9];
                c_json.opening_hour = values[10];
                c_json.youtube = values[11];
                c_json.googlemap = values[12];
                c_json.popularity = values[13];
                c_json.index = food_index; 
                ret_data.spots.push(c_json);  
                food_index++; 
            }
            else if(c_json.type == 'HOTEL') {
                // id, name_kor, img, img2, img3, website, description, agoda_id, subtype, year_opened, year_renovated, number_of_rooms, star_rating, agoda_rates_from_usd, agoda_chain_id, chain_name, checkin_time, checkout_time, youtube, name_local
                c_json.name = values[2];
                c_json.img = values[3];
                c_json.img2 = values[4];
                c_json.img3 = values[5];
                c_json.hotel_website = values[6];
                c_json.description = values[7];
                c_json.agoda_id = values[8];
                //c_json.subtype = values[13]+'성급 |'+values[9];
                c_json.subtype = TMI_ConvertHotelPriceText(values[14]);
                c_json.year_opened = values[10];
                c_json.year_renovated = values[11];
                c_json.number_of_rooms = values[12];
                //c_json.star_rating = values[13];
                //c_json.agoda_rates_from_usd = values[14];
                c_json.agoda_chain_id = values[15];
                c_json.chain_name = values[16];
                c_json.checkin_time = values[17];
                c_json.checkout_time = values[18];
                c_json.youtube = values[19];
                c_json.index = hotel_index; 
                ret_data.spots.push(c_json);  
                hotel_index++;
            }
            else if(c_json.type == 'ETC') {
                // id, name_kor, img, subtype, website, description, opening_hour, youtube, google_map_link, name_local 
                c_json.name = values[2];
                c_json.img = values[3];
                c_json.img2 = '';
                c_json.img3 = '';
                c_json.subtype = values[4];
                c_json.website = values[5];
                c_json.description = values[6];
                c_json.opening_hour = values[7];
                c_json.youtube = values[8];
                c_json.googlemap = values[9];
                c_json.index = etc_index; 
                ret_data.spots.push(c_json);  
                etc_index++;
            }
            else {
                console.log("ERROR: Invalid data type!")   ;
            }    
        }
        

    }

/*
    var ret_data = {};
    ret_data.map_url = 'https://www.google.com/maps/d/embed?mid=12eefVMssXrmw0ujP7YnqeLpbjT0PTbk&ehbc=2E312F&noprof=1';
    ret_data.spots = [];

    var p_item = {};
    p_item.type = 'PLACE';
    p_item.name = '세인트폴 성당 유적';
    p_item.subtype = '성당';
    p_item.img = 'https://lh3.googleusercontent.com/places/AJDFj41j8zxwDyDRHFAiYyV7T0TjVmEVooR3hWd4cYjZ12qMet34Ay-KAORJ-rQMA29okUaSTWfOWrBqUXZAu5uyIrAxWKJIJfTkCu4=s1600-w960-h200';
    ret_data.spots.push(p_item);


    var f_item = {};
    f_item.type = 'FOOD';
    f_item.name = '웡치케이';
    f_item.subtype = '광동식';
    f_item.img = 'https://lh3.googleusercontent.com/places/AJDFj409zRyxOb6YmgyenhqcmnKypkw0pKe2aSnTXiA7oIQ3MkzGVS4K_zoIizP6yXKy8eqTv4DIuod3kErRFUx8hEFEsu-zFPgYJwE=s1600-w4624-h200';
    ret_data.spots.push(f_item);

    
    var h_item = {};
    h_item.type = 'HOTEL';
    h_item.name = '베네시안 마카오';
    h_item.subtype = '호텔';
    h_item.img = 'https://lh3.googleusercontent.com/places/AJDFj43Pv2z8U-txgUGuKi2VnzPIJitj07GuR0nXix7m4z23RDeM37ddPuMO9uQ2eNWtMGO5HfttbYObUupuzJyyifI0hmYyrx-9vGQ=s1600-w754-h200';
    ret_data.spots.push(h_item);

*/
    return ret_data;
}





export function TMI_getHotelYearText(hd) {

    console.log(hd.year_opened);
    console.log(hd.year_renovated);

    var ret = "";
    if(hd.year_opened !== "") {
        ret = hd.year_opened+"년 오픈";
    }
    if(hd.year_renovated !== "") {
        ret = hd.year_renovated+"년 리모델링";
    }

    return ret;
}






// ---------------------------------------------------------------------------------------------------------------------------------------



export function PINO_ParseSpotData(raw_data) {

    var spot_json = {};
    spot_json.hotels = [];
    spot_json.foods = [];
    spot_json.attractions = [];
    
    const rows = raw_data.split("\r\n");
    for(let i = 0; i < rows.length; i++){
        //console.log(rows[i]);

        let values = rows[i].split("	");

        if(values[0] == 'HOTEL') {
            var h_json = {};
            h_json.hotel_id = values[1];
            h_json.chain_id = parseInt(values[2]);
            if( REGISTERED_CHAIN_IDS.has(h_json.chain_id) === false ) {
                h_json.chain_id = 0;
            }
            h_json.hotel_name = values[3];
            h_json.lat = parseFloat(values[4]);
            h_json.lng = parseFloat(values[5]);
            h_json.checkin_time = values[6];
            h_json.checkout_time = values[7];
            h_json.photo_1 = values[8];
            h_json.photo_2 = values[9];
            h_json.photo_3 = values[10];
            h_json.photo_4 = values[11];
            h_json.photo_5 = values[12];
            h_json.rates_from = values[13];
            h_json.number_of_reviews = values[14];
            h_json.rating_average = values[15];
            h_json.number_of_floors = values[16];
            h_json.year_opened = values[17];
            h_json.year_renovated = values[18];
            h_json.number_of_rooms = values[19];
            h_json.hotel_star = values[20] + "성급";
            h_json.address = values[21];
            h_json.google_id = values[22];
            h_json.region = values[23];
            h_json.options = values[24];
        
            //h_json.price = "가격 검색중";
            //h_json.price_link = AGODA_LINK_URL+h_json.hotel_id;
    
            spot_json.hotels.push(h_json);
        }
        else if(values[0] == 'FOODS') {
            var f_json = {};
            f_json.title = values[1];

            spot_json.foods.push(f_json);            
        }
        else if(values[0] == 'ATTRACTIONS') {
            var a_json = {};
            a_json.title = values[1];

            spot_json.attractions.push(a_json);
        }
        

    }

    

    return spot_json;
}









export const AGODA_LINK_URL = 'https://www.agoda.com/partners/partnersearch.aspx?pcs=1&cid=1900596&hl=ko-kr&hid='; 

//export const PINO_API_URL = 'https://hj26v621uh.execute-api.ap-northeast-2.amazonaws.com/prod';
//export const PINO_API_URL = 'https://d2q1hc1r7ctewq.cloudfront.net';
//export const PINO_API_URL = 'https://api.hocancesitga.com';
export const PINO_API_URL = 'https://api.pricemate.link';


const API_CACHE_DAYS = 100;    // 10 일 = 86400초 x 10일 x 1000 msec

export const YOUTUBE_API_URL = 'https://www.googleapis.com/youtube/v3';
//export const YOUTUBE_API_KEY = 'AIzaSyAtpGDKBEoXaNjRJ_sTeykecnmxFhkadk4';// AIzaSyCLx7OFHvpi8sbjBEeXqaRhETPqrKuKil8



const HOTEL_RENOVATION_YEAR_LIMIT = 2018;
const HOTEL_OPEN_YEAR_LIMIT = 2018;


export const Google_map_req_libs = ['places'];

export const REGISTERED_CHAIN_IDS = new Map([
    //[-1, "전체"],
    [14, "힐튼"],
    [21, "메리어트"],
    [15, "하얏트"],
    [16, "IHG"],
    [3, "아코르"],
    [3580, "신라"],
    [808, "롯데"],
    [0, "기타"]
])





export function PINO_getHotelYearText(hd) {

    var ret = "";
    if(hd.year_renovated !== "" && parseInt(hd.year_renovated) > HOTEL_RENOVATION_YEAR_LIMIT) {
        ret = hd.year_renovated+"년 리모델링";
    }
    else if(hd.year_opened !== ""  && parseInt(hd.year_opened) > HOTEL_OPEN_YEAR_LIMIT) {
        ret = hd.year_opened+"년 개장";
    }

    return ret;
}

export function ChangeAgodaPhotoBigSize(url) {
    // https://pix5.agoda.net/hotelimages/24333639/0/c2aa1c269a9920d0cb1849a72a4ef69c.jpg?s=312x&ca=20&ce=0
    if(url !== undefined) {
        return url.replace('s=312x', 's=924x');
    }
    else {
        return "";
    }
}




export function PINO_ParseCityInfoData(row) {
    let values = row.split("	");
    
    var city_json = {};
    city_json.id = values[0];
    city_json.name = values[3];
    city_json.img = values[6];
    city_json.flight_hour = values[17];
    city_json.flight_min = values[18];
    city_json.layover = values[19];
            
    return city_json;
}




export function PINO_ParseHotelData(row) {
    let values = row.split("	");
    
    var hotel_json = {};
    hotel_json.hotel_id = values[0];
    hotel_json.chain_id = parseInt(values[1]);
    if( REGISTERED_CHAIN_IDS.has(hotel_json.chain_id) === false ) {
        hotel_json.chain_id = 0;
    }
    hotel_json.hotel_name = values[2];
    hotel_json.lat = parseFloat(values[3]);
    hotel_json.lng = parseFloat(values[4]);
    hotel_json.checkin_time = values[5];
    hotel_json.checkout_time = values[6];
    hotel_json.photo_1 = values[7];
    hotel_json.photo_2 = values[8];
    hotel_json.photo_3 = values[9];
    hotel_json.photo_4 = values[10];
    hotel_json.photo_5 = values[11];
    hotel_json.rates_from = values[12];
    hotel_json.number_of_reviews = values[13];
    hotel_json.rating_average = values[14];
    hotel_json.number_of_floors = values[15];
    hotel_json.year_opened = values[16];
    hotel_json.year_renovated = values[17];
    hotel_json.number_of_rooms = values[18];
    hotel_json.hotel_star = values[19] + "성급";
    hotel_json.address = values[20];
    hotel_json.google_id = values[21];
    hotel_json.region = values[22];
    hotel_json.options = values[23];

        
    //hotel_json.price = "가격 검색중";
    //hotel_json.price_link = AGODA_LINK_URL+hotel_json.hotel_id;

    return hotel_json;
}

export function PINO_IsExpiredDate(time_str) {
    var elap_days = (Date.now() - Date.parse(time_str)) / 86400000;
    if( elap_days > API_CACHE_DAYS) {
        console.log("PINO_IsExpiredDate - expired", elap_days);
        return true;
    }

    console.log("PINO_IsExpiredDate - not expired", elap_days);
    return false;
}




export function PINO_convertYoutubeVideoToPinoVideo(youtube_video_obj) {

    var pino_videos = [];
    for(var i=0;i<youtube_video_obj.items.length;i++) {
        var item = youtube_video_obj.items[i];
        
        var parsed_item = {};
        parsed_item.video_id = item.id.videoId;
        parsed_item.title = item.snippet.title;
        parsed_item.ch_id = item.snippet.channelId;
        parsed_item.ch_name = item.snippet.channelTitle;
        parsed_item.desc = item.snippet.description;
        parsed_item.upload_date = item.snippet.publishedAt;
        parsed_item.thumb = item.snippet.thumbnails.medium.url;

        pino_videos.push(parsed_item);
    }

    return pino_videos;

}


export function PINO_convertGooglePlaceToPinoPlace(google_place_obj) {
    var pino_place = {};
    pino_place.place_id = google_place_obj.place_id;
    pino_place.name = google_place_obj.name;
    pino_place.types = google_place_obj.types;
    pino_place.lat = google_place_obj.geometry.location.lat();
    pino_place.lng = google_place_obj.geometry.location.lng();
    pino_place.address = google_place_obj.formatted_address;
    pino_place.google_url = google_place_obj.url;
    if("formatted_phone_number" in google_place_obj) {
        pino_place.phone = google_place_obj.formatted_phone_number;
    }
    if("website" in google_place_obj) {
        pino_place.website = google_place_obj.website;
    }
    if("photos" in google_place_obj) {
        pino_place.photos = [];
        for(var i=0;i<1;i++) {
            var photo_item = {};
            photo_item.url = google_place_obj.photos[i].getUrl();
            console.log("★★★★★★★★★★ CHARGED : GET URL = 7")
            photo_item.html_attributions = google_place_obj.photos[i].html_attributions[0];
            pino_place.photos.push(photo_item);
        }
    }
    if("opening_hours" in google_place_obj) {
        pino_place.opening_hours = [];
        for( i=0;i<google_place_obj.opening_hours.weekday_text.length;i++) {
            pino_place.opening_hours.push(google_place_obj.opening_hours.weekday_text[i]);
        }
    }
    if("rating" in google_place_obj) {
        pino_place.rating = google_place_obj.rating;
    }
    if("user_ratings_total" in google_place_obj) {
        pino_place.rating_count = google_place_obj.user_ratings_total;
    }
    else {
        pino_place.rating_count = 0;
    }
    if("reviews" in google_place_obj) {
        pino_place.reviews = [];
        for( i=0;i<google_place_obj.reviews.length;i++) {
            var rv_item = {};
            rv_item.text = google_place_obj.reviews[i].text;
            rv_item.rating = google_place_obj.reviews[i].rating;
            rv_item.author_name = google_place_obj.reviews[i].author_name;
            rv_item.author_url = google_place_obj.reviews[i].author_url;
            rv_item.author_img = google_place_obj.reviews[i].profile_photo_url;
            rv_item.time = google_place_obj.reviews[i].time;
            rv_item.lang = google_place_obj.reviews[i].language;
            pino_place.reviews.push(rv_item);
        }
    } 
    if("address_components" in google_place_obj) {
        for( i=0;i<google_place_obj.address_components.length;i++) {
            if(google_place_obj.address_components[i].types.indexOf('country')>=0) {
                pino_place.cc = google_place_obj.address_components[i].short_name;
                break;
            }
        }
    }

    pino_place.saved_timestamp = Date.now();
    
    return pino_place;
}



export function PINO_DisplayDateText(date) {
    if(date == undefined) {
        return "";
    }
    return (date.getMonth()+1) + "월" + date.getDate() + "일";
}


export function PINO_convertEpochToDisplayTime(epoch_time) {

    var input = epoch_time;
    if(input < 10000000000) {
        input *= 1000;
    }

    var date = new Date(input);

    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();

    return year+"-"+month+"-"+day;
}


/*
const PinoUtil = {
    AGODA_LINK_URL : 'https://www.agoda.com/partners/partnersearch.aspx?pcs=1&cid=1900596&hl=ko-kr&hid='

    
};
  
export default PinoUtil;
*/






const PINO_TOUR_DATE_KEY = 'pino_tour_date';

export function PINO_saveTourDate(st_date, end_date, num_adult = 2, num_children = 0) {
    var save_item = {};
    save_item.st_date = st_date;
    save_item.end_date = end_date;
    save_item.num_adult = num_adult;
    save_item.num_children = num_children;
    localStorage.setItem(PINO_TOUR_DATE_KEY, JSON.stringify(save_item));
}


export function PINO_loadTourDate() {
    var ret_data = {};
    var today = new Date();

    var td = localStorage.getItem(PINO_TOUR_DATE_KEY);
    if(td != null) {
        ret_data = JSON.parse(td);
        var saved_st_date = new Date(ret_data.st_date);
        if(saved_st_date > today & 'num_adult' in ret_data & 'num_children' in ret_data) { //  saved date is valid
            return ret_data;
        }
        else {      // saved date is expired
            localStorage.removeItem(PINO_TOUR_DATE_KEY);
        }
    }

    // return new default today date
    var today_str =  today.getFullYear() +"-"+ ("0" + (1 + today.getMonth())).slice(-2) +"-"+ ("0" + today.getDate()).slice(-2);
    var tomorrow = new Date(today.setDate(today.getDate()+1));
    var tomorrow_str =  tomorrow.getFullYear() +"-"+ ("0" + (1 + tomorrow.getMonth())).slice(-2) +"-"+ ("0" + tomorrow.getDate()).slice(-2);
    ret_data.st_date = today_str;
    ret_data.end_date = tomorrow_str;  
    ret_data.num_adult = 2;  
    ret_data.num_children = 0;  
    return ret_data;    
}


export function PINO_getDispDateText(date) {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);

    // yyyy-mm-dd 형식의 문자열로 변환합니다.
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}