//import logo from './logo.svg';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';

import Header from './Header';
import Footer from './Footer';
import CityList from './CityList/CityList';
import CityPage from './CityPage/CityPage';
import CityPage2 from './CityPage2/CityPage2';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path='/' element={<CityList />} />
          <Route path='/:city_id' element={<CityPage2 />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
